import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@skunexus/component-catalog';

function ResolveButton({ onClick }) {
    return (
        <Button size="md" className="mt-2" onClick={onClick}>
            Resolve Purchase Order
        </Button>
    );
}

ResolveButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};
ResolveButton.defaultProps = {};

export default ResolveButton;
