import React from 'react';
import debounce from 'lodash/debounce';
import findIndex from 'lodash/findIndex';
import { CellTextInput } from '@skunexus/vendor-product-grid';

export default {
  name: 'customVendorProductGrid',
  before: [],
  after: [],
  setup: (app) => {
    const vendorProductRouter = app.routes.getItem('vendorProducts');

    if (vendorProductRouter) {
      const vendorProductRouterProps = vendorProductRouter.props;

      const handleChange = debounce(
        ({ action, vendorId, vendorProductId, key, value }) => {
          action({
            vendorId,
            vendorProductId,
            propertyKey: key,
            propertyValue: value,
          });
        },
        500
      );

      const customGridData = (gridProps, { vendorId, edit }) => {
        gridProps.columns.push({
          field: 'customValues.uom',
          label: 'UOM',
          type: 'string',
          isSortable: false,
          isFilterable: true,
          template: ({ originalValue: { value, row } }) => (
            <CellTextInput
              propertyKey={`uom-${row.id}`}
              propertyValue={value}
              placeholder=""
              onChange={(_value) => {
                handleChange({
                  action: edit,
                  vendorId,
                  vendorProductId: row.id,
                  key: 'customValues.uom',
                  value: _value,
                });
              }}
            />
          ),
        });

        gridProps.columns.push({
          field: 'customValues.sales_uom_quantity',
          label: 'Sales UOM Quantity',
          type: 'number',
          isSortable: false,
          isFilterable: true,
          template: ({ originalValue: { value, row } }) => (
            <CellTextInput
              type="number"
              propertyKey={`sales_uom_quantity-${row.id}`}
              propertyValue={value}
              placeholder=""
              onChange={(_value) => {
                handleChange({
                  action: edit,
                  vendorId,
                  vendorProductId: row.id,
                  key: 'customValues.sales_uom_quantity',
                  value: _value,
                });
              }}
            />
          ),
        });

        gridProps.columns.splice(5, 0, {
          field: 'customValues.buffer_quantity',
          label: 'Buffer Quantity',
          type: 'number',
          isSortable: false,
          isFilterable: true,
          template: ({ originalValue: { value, row } }) => (
            <CellTextInput
              type="number"
              propertyKey={`buffer_quantity-${row.id}`}
              propertyValue={value}
              placeholder=""
              onChange={(_value) => {
                handleChange({
                  action: edit,
                  vendorId,
                  vendorProductId: row.id,
                  key: 'customValues.buffer_quantity',
                  value: _value,
                });
              }}
            />
          ),
        });

        gridProps.columns.push({
          field: 'customValues.shipping_cost',
          label: 'Additional Shipping Cost',
          type: 'number',
          isSortable: false,
          isFilterable: true,
          template: ({ originalValue: { value, row } }) => (
            <CellTextInput
              type="number"
              propertyKey={`shipping_cost-${row.id}`}
              propertyValue={value}
              placeholder=""
              onChange={(_value) => {
                handleChange({
                  action: edit,
                  vendorId,
                  vendorProductId: row.id,
                  key: 'customValues.shipping_cost',
                  value: _value,
                });
              }}
            />
          ),
        });

        gridProps.columns.push({
          field: 'customValues.daily_quantity_limit',
          label: 'Daily Quantity Limit',
          type: 'number',
          isSortable: false,
          isFilterable: true,
          template: ({ originalValue: { value, row } }) => (
            <CellTextInput
              type="number"
              propertyKey={`daily_quantity_limit-${row.id}`}
              propertyValue={value}
              placeholder=""
              onChange={(_value) => {
                handleChange({
                  action: edit,
                  vendorId,
                  vendorProductId: row.id,
                  key: 'customValues.daily_quantity_limit',
                  value: _value,
                });
              }}
            />
          ),
        });

        const priceColIndex = findIndex(gridProps.columns, ['field', 'price']);
        if (priceColIndex >= 0) {
          gridProps.columns[priceColIndex].label = 'Cost';
        }

        return gridProps;
      };

      vendorProductRouter.props = (state) => ({
        ...vendorProductRouterProps(state),
        isCustomFields: true,
        customGridData,
      });

      app.routes.changeItem('vendorProducts', vendorProductRouter);
    }

    const vendorProductPortalRouter =
      app.vendorRoutes.getItem('vendorProducts');
    if (vendorProductPortalRouter) {
      const vendorProductProps = vendorProductPortalRouter.props;

      const customGridData = (gridProps, { vendorId, edit }) => {
        gridProps.columns.push({
          field: 'customValues.shipping_cost',
          label: 'Additional Shipping Cost',
          type: 'number',
          isSortable: false,
          isFilterable: true,
        });

        return gridProps;
      };
      vendorProductPortalRouter.props = (state) => ({
        ...vendorProductProps(state),
        customGridData,
      });

      app.vendorRoutes.changeItem('vendorProducts', vendorProductPortalRouter);
    }
  },
};
