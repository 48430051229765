import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectors, actions } from '@skunexus/purchase-order-overview';
import { getGridsSelections } from '../../../../core/selectors';

import Components from './ResolvePo';

const { getDetails, getItemsRows, getItemsIsLoading, getItemsTotalSize, getItemsRefreshToken } = selectors;

const mapStateToProps = createStructuredSelector({
    details: getDetails,
    values: getItemsRows,
    isLoading: getItemsIsLoading,
    totalSize: getItemsTotalSize,
    refreshToken: getItemsRefreshToken,
    gridSelections: getGridsSelections,
});

const mapDispatchToProps = {
    fetchRows: actions.lineItems.fetchRows.TRIGGER,
};

export default connect(mapStateToProps, mapDispatchToProps)(Components);
