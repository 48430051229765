import React from 'react';
import PropTypes from 'prop-types';
import ResolvePo from './components/ResolvePo';

function ResolvePurchaseOrder({ cancelPO, cancelItems }) {
    const handleCancel = ({ details, selectedItems, setModalData }) => {
        setModalData((state) => ({
            ...state,
            isLoading: true,
        }));
        cancelPO({
            id: details.id,
            data: {
                item_ids: selectedItems,
            },
            onSuccess: () => {
                setModalData((state) => ({
                    ...state,
                    isLoading: false,
                    isOpen: false,
                }));

                window.location.reload();
            },
            onFailure: () => {
                setModalData((state) => ({
                    ...state,
                    isLoading: false,
                }));
            },
        });
    };

    const handleCancelAndResend = ({ details, selectedItems, setModalData }) => {
        if (selectedItems.length === 0) {
            setModalData((state) => ({
                ...state,
                isError: true,
            }));
        } else {
            setModalData((state) => ({
                ...state,
                isLoading: true,
                isError: false,
            }));
            cancelItems({
                id: details.id,
                data: {
                    item_ids: selectedItems,
                },
                onSuccess: () => {
                    setModalData((state) => ({
                        ...state,
                        isLoading: false,
                        isOpen: false,
                    }));
                    window.location.reload();
                },
                onFailure: () => {
                    setModalData((state) => ({
                        ...state,
                        isLoading: false,
                    }));
                },
            });
        }
    };

    return (
        <ResolvePo
            resetButtonLabel={(selectedItemsCount) => `Cancel PO and Redecide Order (${selectedItemsCount})`}
            confirmButtonLabel={(selectedItemsCount) => `Cancel Items and Resend PO (${selectedItemsCount})`}
            onResetButtonClick={handleCancel}
            onConfirmButtonClick={handleCancelAndResend}
        />
    );
}

ResolvePurchaseOrder.propTypes = {
    cancelPO: PropTypes.func.isRequired,
    cancelItems: PropTypes.func.isRequired,
};

ResolvePurchaseOrder.defaultProps = {};

export default ResolvePurchaseOrder;
