import { call, put, all, takeEvery } from 'redux-saga/effects';
import { ajaxExceptionMessage } from '@skunexus/utils';

import * as API from '../../api';
import { cancelPO, cancelItems, keheCancelItems, keheCancelItemsAndRedecide } from './actions';

function* onCancelPurchaseOrder({ payload }) {
    try {
        yield put(cancelPO.REQUEST(payload));
        yield call(API.cancelOrder, payload);
        yield put(cancelPO.SUCCESS('Purchase Order is canceled'));
        if (payload.onSuccess) {
            yield call(payload.onSuccess);
        }
    } catch (e) {
        yield put(cancelPO.FAILURE(ajaxExceptionMessage(e)));
        if (payload.onFailure) {
            yield call(payload.onFailure);
        }
    } finally {
        yield put(cancelPO.FULFILL());
    }
}

function* onCancelItems({ payload }) {
    try {
        yield put(cancelItems.REQUEST(payload));
        yield call(API.cancelItems, payload);
        yield put(cancelItems.SUCCESS('Purchase Order Items are canceled'));
        if (payload.onSuccess) {
            yield call(payload.onSuccess);
        }
    } catch (e) {
        yield put(cancelItems.FAILURE(ajaxExceptionMessage(e)));
        if (payload.onFailure) {
            yield call(payload.onFailure);
        }
    } finally {
        yield put(cancelItems.FULFILL());
    }
}

function* onKeheCancelItems({ payload }) {
    try {
        yield put(keheCancelItems.REQUEST(payload));
        yield call(API.keheCancelItems, payload);
        yield put(keheCancelItems.SUCCESS('Purchase Order Items are canceled'));
        if (payload.onSuccess) {
            yield call(payload.onSuccess);
        }
    } catch (e) {
        yield put(keheCancelItems.FAILURE(ajaxExceptionMessage(e)));
        if (payload.onFailure) {
            yield call(payload.onFailure);
        }
    } finally {
        yield put(keheCancelItems.FULFILL());
    }
}

function* onKeheCancelItemsAndRedecide({ payload }) {
    try {
        yield put(keheCancelItemsAndRedecide.REQUEST(payload));
        yield call(API.keheCancelItemsAndRedecide, payload);
        yield put(keheCancelItemsAndRedecide.SUCCESS('Purchase Order Items are canceled'));
        if (payload.onSuccess) {
            yield call(payload.onSuccess);
        }
    } catch (e) {
        yield put(keheCancelItemsAndRedecide.FAILURE(ajaxExceptionMessage(e)));
        if (payload.onFailure) {
            yield call(payload.onFailure);
        }
    } finally {
        yield put(keheCancelItemsAndRedecide.FULFILL());
    }
}

function* resendSagas() {
    yield all([
        takeEvery(cancelPO.TRIGGER, onCancelPurchaseOrder),
        takeEvery(cancelItems.TRIGGER, onCancelItems),
        takeEvery(keheCancelItems.TRIGGER, onKeheCancelItems),
        takeEvery(keheCancelItemsAndRedecide.TRIGGER, onKeheCancelItemsAndRedecide),
    ]);
}

export default resendSagas;
