import { Module, sagas } from './packages/ResendPurchaseOrder/core';

export default {
    name: 'customPurchaseOrderOverview',
    before: [],
    after: [],
    setup: (app) => {
        const purchaseOrderOverview = app.routes.getItem('purchaseOrderOverview');

        if (purchaseOrderOverview) {
            const purchaseOrderOverviewProps = purchaseOrderOverview.props;

            purchaseOrderOverview.props = (state) => ({
                ...purchaseOrderOverviewProps(state),
                customInfoWrappers: [Module],
            });

            app.routes.changeItem('purchaseOrderOverview', purchaseOrderOverview);
            app.sagas.addItem('purchaseOrderOverviewResend', sagas);
        }
    },
};
