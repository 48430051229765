import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Modal } from '@skunexus/component-catalog';

import styles from '../styles.module.css';

function ResolveModal({ modalProps, modalData, children }) {
    return (
        <Modal headerLabel="Resolve Purchase Order" size="lg" className={styles.fullWidth} {...modalProps}>
            <p>
                <strong>Please select the item(s) that is unavailable</strong>
            </p>
            {children}
            {get(modalData, 'isError', false) && (
                <p className={styles.error}>To cancel items, select at least on of them.</p>
            )}
        </Modal>
    );
}

ResolveModal.propTypes = {
    modalProps: PropTypes.object,
    modalData: PropTypes.object,
    children: PropTypes.any,
};
ResolveModal.defaultProps = {
    modalProps: {},
    modalData: {},
    children: null,
};

export default ResolveModal;
