import KittedProductButton from './packages/KittedProductButton/components/KittedProductButton';

export default {
    name: 'customProductStock',
    before: [],
    after: [],
    setup: (app) => {
        const routeName = 'productDetailsStock';
        const route = app.routes.getItem(routeName);
        if (route) {
            const customHeaderComponent = KittedProductButton;

            const routeProps = route.props;
            route.props = (state) => ({
                ...routeProps(state),
                customHeaderComponent,
            });
            app.routes.changeItem(routeName, route);
        }
    },
};
