export default {
    name: 'ordersScheduled',
    before: [],
    after: [],
    setup: (app) => {
        const orderMenuItem = app.sideMenuItems.getItem('orders');
        orderMenuItem.items = [
            {
                label: orderMenuItem.label,
                link: orderMenuItem.link,
                icon: orderMenuItem.icon,
            },
            {
                label: 'Manager Review',
                icon: 'customers',
                link: '/manager-review',
                permission: [],
            },
        ];
        app.sideMenuItems.changeItem('orders', orderMenuItem);

        app.routes.addItem('managerReviewGrid', {
            name: 'ManagerReviewGrid',
            path: '/manager-review',
            title: 'Manager Review',
            permission: [],
            props: () => ({
                customerDetailsPageRoute: '/customer/:id',
                orderDetailsPageRoute: '/orders/:id',
            }),
            loader: () => import('./packages/ManagerReviewGrid/core'),
        });
    },
};
