import { useState } from 'react';
import { get } from 'lodash';

import { ajaxExceptionMessage } from '@skunexus/utils';

import * as API from '../../../api';

const useExport = ({ errorNotification }) => {
    const [isLoading, setLoading] = useState(false);

    const fetchExport = async ({ orderDecisionRuleId }) => {
        setLoading(true);
        try {
            const result = await API.exportPrioritizedVendors({ id: orderDecisionRuleId });

            const status = get(result, `status`);
            if (status !== 200) {
                throw new Error(`Couldn't export data`);
            }
        } catch (e) {
            errorNotification(ajaxExceptionMessage(e));
        } finally {
            setLoading(false);
        }
    };

    return [{ isLoading }, { fetchExport }];
};

export default useExport;
