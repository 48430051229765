import React from 'react';
import PropTypes from 'prop-types';
import { get, find, reduce } from 'lodash';
import ResolvePo from '../ResolvePurchaseOrder/components/ResolvePo';

function KeheResolvePurchaseOrder({ cancelItemsAndRedecide, cancelItems }) {
    const handleSuccess = (setModalData) => {
        setModalData((state) => ({
            ...state,
            isLoading: false,
            isOpen: false,
        }));

        window.location.reload();
    };

    const handleFailure = (setModalData) => {
        setModalData((state) => ({
            ...state,
            isLoading: false,
        }));
    };

    const handleSubmit = ({ details, selectedItems, setModalData, endpoint }) => {
        if (selectedItems.length === 0) {
            setModalData((state) => ({
                ...state,
                isError: true,
            }));
        } else {
            setModalData((state) => ({
                ...state,
                isLoading: true,
                isError: false,
            }));
            endpoint({
                id: details.id,
                data: {
                    items: selectedItems.map((itemId) => ({
                        item_id: itemId,
                    })),
                },
                onSuccess: () => handleSuccess(setModalData),
                onFailure: () => handleFailure(setModalData),
            });
        }
    };

    const handleCancelAndRedecide = ({ details, selectedItems, setModalData }) => {
        handleSubmit({ details, selectedItems, setModalData, endpoint: cancelItemsAndRedecide });
    };

    const handleCancelItems = ({ details, selectedItems, setModalData }) => {
        handleSubmit({ details, selectedItems, setModalData, endpoint: cancelItems });
    };

    const customGridConfig = (data) => ({
        ...data,
        config: {
            ...data.config,
            pagination: {
                currentPage: 1,
                pageSize: 999,
            },
        },
    });

    const getFailedVendorSkus = (poDetails) => {
        const failedVendorSkusCustomFields = find(get(poDetails, 'customValues', []), [
            'custom_field_id',
            'vendor_failed_skus',
        ]);
        if (failedVendorSkusCustomFields) {
            return get(failedVendorSkusCustomFields, 'value', '').split(',');
        }

        return [];
    };

    const getTrackingCodeSumQty = (trackingCodes) =>
        reduce(trackingCodes, (result, { quantity }) => result + quantity, 0);

    const getFilteredValues = (gridProps, props) => {
        const poDetails = get(props, 'details', {});
        const failedVendorSkus = getFailedVendorSkus(poDetails);

        return gridProps.values.filter(({ relatedVendorProduct, quantity, trackingCodes }) => {
            const trackingCodeSumQty = getTrackingCodeSumQty(trackingCodes);
            return failedVendorSkus.includes(get(relatedVendorProduct, 'sku')) || trackingCodeSumQty !== quantity;
        });
    };

    const customGridData = (gridProps, props) => {
        const filteredValues = getFilteredValues(gridProps, props);

        return {
            ...gridProps,
            config: {
                ...gridProps.config,
                withFooter: false,
            },
            itemsCount: filteredValues.length,
            values: filteredValues,
        };
    };

    return (
        <ResolvePo
            resetButtonLabel={(selectedItemsCount) => `Cancel Items and Redecide (${selectedItemsCount})`}
            confirmButtonLabel={(selectedItemsCount) => `Cancel Items (${selectedItemsCount})`}
            customGridConfig={customGridConfig}
            customGridData={customGridData}
            onResetButtonClick={handleCancelAndRedecide}
            onConfirmButtonClick={handleCancelItems}
        />
    );
}

KeheResolvePurchaseOrder.propTypes = {
    cancelItemsAndRedecide: PropTypes.func.isRequired,
    cancelItems: PropTypes.func.isRequired,
};

KeheResolvePurchaseOrder.defaultProps = {};

export default KeheResolvePurchaseOrder;
