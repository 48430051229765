import { connect } from 'react-redux';

import { selectors } from '@skunexus/purchase-order-overview';

import { resend } from '../../core/actions';
import Module from './Module';

const { getDetails, getStatuses } = selectors;

const mapStateToProps = (state) => ({
    details: getDetails(state),
    statuses: getStatuses(state),
});

const mapDispatchToProps = {
    repushOrder: resend.repushOrder.TRIGGER,
};

export default connect(mapStateToProps, mapDispatchToProps)(Module);
