import { get, has, set } from 'lodash';

const DEFAULT_ACTION_PAYLOAD = {
    filename: null,
    last_imported: null,
};

export const prepareFormData = ({ formData }) => formData;

export const preparePayloadData = ({ payloadData, formData }) => {
    if (!has(payloadData, 'data.action.payload')) {
        if (!get(payloadData, 'data.id')) {
            set(payloadData, 'data.action.payload', { ...DEFAULT_ACTION_PAYLOAD });
        } else {
            set(payloadData, 'data.action.payload', get(formData, 'action.payload', { ...DEFAULT_ACTION_PAYLOAD }));
        }
    }

    return payloadData;
};
