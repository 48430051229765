import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';

import { ajaxExceptionMessage } from '@skunexus/utils';

import * as API from '../../../api';

const useImport = ({ errorNotification, successNotification, fetchDetails }) => {
    const [isLoading, setLoading] = useState(false);
    const { id: detailsId } = useParams();

    const fetchImport = async ({ orderDecisionRuleId, file }) => {
        setLoading(true);
        try {
            const result = await API.importPrioritizedVendors({ id: orderDecisionRuleId, file });

            const status = get(result, `status`);
            if (status !== 200) {
                throw new Error(`Couldn't export data`);
            } else {
                successNotification('Csv file import successfully made.');
                fetchDetails({ id: detailsId });
            }
        } catch (e) {
            errorNotification(ajaxExceptionMessage(e));
        } finally {
            setLoading(false);
        }
    };

    return [{ isLoading }, { fetchImport }];
};

export default useImport;
