import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { resolve } from '../../core/actions';

import Components from './ResolvePurchaseOrder';

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {
    cancelPO: resolve.cancelPO.TRIGGER,
    cancelItems: resolve.cancelItems.TRIGGER,
};

export default connect(mapStateToProps, mapDispatchToProps)(Components);
