import { connect } from 'react-redux';
import { actions as notification } from '@skunexus/notification';

import Components from './KittedProductButton';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    errorNotification: notification.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(Components);
