import UserModel from '../../../UserModel';

import {
    TITLE_PREFIX,
    CYCLE_ACTIVE_ROUTE,
    CYCLE_DETAILS_ROUTE,
    CYCLE_WAVE_DETAILS_ROUTE,
    CYCLE_MANAGER_ROUTE,
} from './consts';

import { PRODUCTS_DETAILS_PATH } from '../products/consts';
import { WAREHOUSES_DETAILS_PATH } from '../warehouses/consts';
import { EMPTY_TITLE_CRUMB } from '../constants';

export default (app) => {
    const tabs = app.cycleCountTabs.getAll();
    const groupsTabs = app.cycleCountGroupsTabs.getAll();

    const routers = {
        cycleCountActiveGrid: {
            path: app.cycleCountTabs.getItem('cycleCountActiveGrid').link,
            title: app.cycleCountTabs.getItem('cycleCountActiveGrid').title,
            permission: app.cycleCountTabs.getItem('cycleCountActiveGrid').permission,
            breadcrumbConfig: {
                title: `${TITLE_PREFIX} > ${app.cycleCountTabs.getItem('cycleCountActiveGrid').label}`,
                isTab: true,
                clearPath: true,
            },
            props: () => ({
                tabs,
                detailsViewPageRotue: CYCLE_DETAILS_ROUTE,
                canAddCycleCount: UserModel.getCurrentUser().hasAnyPermissions('cycle-counts.create'),
            }),
            loader: () => import('@skunexus/cycle-count-grid'),
        },
        cycleCountHistoricalGrid: {
            path: app.cycleCountTabs.getItem('cycleCountHistoricalGrid').link,
            title: app.cycleCountTabs.getItem('cycleCountHistoricalGrid').title,
            permission: app.cycleCountTabs.getItem('cycleCountHistoricalGrid').permission,
            breadcrumbConfig: {
                title: app.cycleCountTabs.getItem('cycleCountHistoricalGrid').label,
            },
            props: () => ({
                tabs,
                detailsViewPageRotue: CYCLE_DETAILS_ROUTE,
                isTab: true,
            }),
            loader: () => import('@skunexus/cycle-count-historical-grid'),
        },
        cycleCountDetails: {
            path: CYCLE_DETAILS_ROUTE,
            title: `${TITLE_PREFIX} > Details`,
            permission: ['cycle-counts.details'],
            breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
            props: () => ({
                waveDetailsPageRoute: CYCLE_WAVE_DETAILS_ROUTE,
                cycleCountGridPageRoute: CYCLE_ACTIVE_ROUTE,
                canAssignUser: UserModel.getCurrentUser().hasAnyPermissions('cycle-counts.assign user'),
                canStartWave: UserModel.getCurrentUser().hasAnyPermissions('cycle-counts.start wave'),
            }),
            loader: () => import('@skunexus/cycle-count-details'),
        },
        cycleCountWaveDetails: {
            path: CYCLE_WAVE_DETAILS_ROUTE,
            title: `${TITLE_PREFIX} > Wave Details`,
            permission: ['cycle-counts.details'],
            breadcrumbConfig: { title: 'Wave Details', isReplaceble: true },
            props: () => ({
                productDetailsPageRoute: PRODUCTS_DETAILS_PATH,
                cycleCountDetailsPageRoute: CYCLE_DETAILS_ROUTE,
                cycleCountGridPageRoute: CYCLE_ACTIVE_ROUTE,
                canAssignUser: UserModel.getCurrentUser().hasAnyPermissions('cycle-counts.assign user'),
                canStartWave: UserModel.getCurrentUser().hasAnyPermissions('cycle-counts.start wave'),
                canCountItems: UserModel.getCurrentUser().hasAnyPermissions('cycle-counts.count item'),
            }),
            loader: () => import('@skunexus/cycle-count-wave-details'),
        },
        cycleCountManagerReviewGrid: {
            path: CYCLE_MANAGER_ROUTE,
            title: `${TITLE_PREFIX} > Manager Review`,
            permission: ['cycle-counts.complete item', 'cycle-counts.set item', 'cycle-counts.transfer item'],
            breadcrumbConfig: { title: `${TITLE_PREFIX} > Manager Review`, clearPath: true },
            props: () => ({
                canSetStock: UserModel.getCurrentUser().hasAnyPermissions('cycle-counts.set item'),
                canSkuTransfer: UserModel.getCurrentUser().hasAnyPermissions('cycle-counts.transfer item'),
                canClearDamagedFlag: UserModel.getCurrentUser().hasAnyPermissions('cycle-counts.complete item'),
            }),
            loader: () => import('@skunexus/cycle-count-manager-review-grid'),
        },
        cycleCountMissingAssignments: {
            path: app.cycleCountGroupsTabs.getItem('cycleCountMissingAssignments').link,
            title: app.cycleCountGroupsTabs.getItem('cycleCountMissingAssignments').title,
            permission: app.cycleCountGroupsTabs.getItem('cycleCountMissingAssignments').permission,
            breadcrumbConfig: {
                title: `${TITLE_PREFIX} > ${app.cycleCountGroupsTabs.getItem('cycleCountMissingAssignments').label}`,
                isTab: true,
                clearPath: true,
            },
            props: () => ({
                tabs: groupsTabs,
                productDetailsPageRoute: PRODUCTS_DETAILS_PATH,
                warehouseDetailsPageRoute: WAREHOUSES_DETAILS_PATH,
                canAssignToGroup: UserModel.getCurrentUser().hasAnyPermissions('cycle-counts.assign count group'),
            }),
            loader: () => import('@skunexus/cycle-count-group-missing-assignment-grid'),
        },
        cycleCountGroupAssignments: {
            path: app.cycleCountGroupsTabs.getItem('cycleCountGroupAssignments').link,
            title: app.cycleCountGroupsTabs.getItem('cycleCountGroupAssignments').title,
            permission: app.cycleCountGroupsTabs.getItem('cycleCountGroupAssignments').permission,
            breadcrumbConfig: {
                title: app.cycleCountGroupsTabs.getItem('cycleCountGroupAssignments').label,
                isTab: true,
            },
            props: () => ({
                tabs: groupsTabs,
                productDetailsPageRoute: PRODUCTS_DETAILS_PATH,
                warehouseDetailsPageRoute: WAREHOUSES_DETAILS_PATH,
                canAssignToGroup: UserModel.getCurrentUser().hasAnyPermissions('cycle-counts.assign count group'),
            }),
            loader: () => import('@skunexus/cycle-count-group-assignment-grid'),
        },
    };
    return routers;
};
