import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Row, Col } from 'reactstrap';

import { GridWithCustomFields } from '@skunexus/custom-fields';

import ResolveButton from '../ResolveButton';
import ResolveModal from '../ResolveModal';
import { resolvePOItemsGridProps } from '../../consts';

function ResolvePO(props) {
    const {
        details,
        values,
        isLoading,
        totalSize,
        refreshToken,
        gridSelections,
        fetchRows,
        customGridData,
        customGridConfig,
        resetButtonLabel,
        confirmButtonLabel,
        onSelectionChange,
        onResetButtonClick,
        onConfirmButtonClick,
    } = props;

    const [modalData, setModalData] = useState({
        isOpen: false,
        isLoading: false,
        isError: false,
    });
    const gridName = useMemo(() => `purchaseOrderLineItem-resolve-grid-${Math.random()}`, [modalData.isOpen]);
    const selectedItems = get(gridSelections, gridName, []);

    useEffect(() => {
        onSelectionChange(selectedItems);
    }, [selectedItems]);

    const handleOpenModal = () => {
        setModalData((state) => ({
            ...state,
            isOpen: true,
            isError: false,
        }));
    };

    const handleCloseModal = () => {
        setModalData((state) => ({
            ...state,
            isOpen: false,
        }));
    };

    const handleResetButtonClick = () => {
        onResetButtonClick({ details, selectedItems, setModalData });
    };

    const handleConfirmationButtonClick = () => {
        onConfirmButtonClick({ details, selectedItems, setModalData });
    };

    const getData = (config) => {
        fetchRows(customGridConfig({ id: details.id, config }));
    };

    const gridProps = {
        ...resolvePOItemsGridProps,
        config: {
            isLoading,
            ...resolvePOItemsGridProps.config,
        },
        values,
        itemsCount: totalSize,
    };

    return (
        <Row className="mt-2">
            <Col>
                <hr />
                <ResolveButton onClick={handleOpenModal} />
                <ResolveModal
                    modalProps={{
                        isOpen: modalData.isOpen,
                        resetOnClick: handleResetButtonClick,
                        resetLabel: resetButtonLabel(selectedItems.length),
                        confirmOnClick: handleConfirmationButtonClick,
                        confirmLabel: confirmButtonLabel(selectedItems.length),
                        cancelOnClick: handleCloseModal,
                        cancelLabel: 'Close',
                        footerProps: {
                            confirmProps: {
                                disabled: selectedItems.length === 0 || modalData.isLoading,
                            },
                            cancelProps: {
                                disabled: modalData.isLoading,
                            },
                            resetProps: {
                                disabled: modalData.isLoading,
                            },
                        },
                    }}
                    modalData={modalData}
                >
                    <GridWithCustomFields
                        domain="purchaseOrderLineItem"
                        id={gridName}
                        data={customGridData(gridProps, props)}
                        getData={getData}
                        refreshToken={refreshToken}
                    />
                </ResolveModal>
            </Col>
        </Row>
    );
}

ResolvePO.propTypes = {
    details: PropTypes.object,
    values: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    totalSize: PropTypes.number.isRequired,
    refreshToken: PropTypes.number.isRequired,
    gridSelections: PropTypes.object,
    fetchRows: PropTypes.func.isRequired,
    customGridData: PropTypes.func,
    customGridConfig: PropTypes.func,
    resetButtonLabel: PropTypes.func,
    confirmButtonLabel: PropTypes.func,
    onSelectionChange: PropTypes.func,
    onResetButtonClick: PropTypes.func,
    onConfirmButtonClick: PropTypes.func,
};

ResolvePO.defaultProps = {
    details: {},
    values: [],
    gridSelections: {},
    customGridData: (data) => data,
    customGridConfig: (data) => data,
    resetButtonLabel: (selected) => `(${selected})`,
    confirmButtonLabel: (selected) => `(${selected})`,
    onSelectionChange: () => {},
    onResetButtonClick: () => {},
    onConfirmButtonClick: () => {},
};

export default ResolvePO;
