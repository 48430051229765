import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { resolve } from '../../core/actions';
import Components from './KeheResolvePurchaseOrder';

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {
    cancelItemsAndRedecide: resolve.keheCancelItemsAndRedecide.TRIGGER,
    cancelItems: resolve.keheCancelItems.TRIGGER,
};

export default connect(mapStateToProps, mapDispatchToProps)(Components);
