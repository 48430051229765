import axios from 'axios';
import { constants } from '@skunexus/utils';

const {
    CONFIG: { API_PATH },
} = constants;

export const rePushOrder = ({ id }) => axios.post(`${API_PATH}/purchase-order/${id}/repush`, {});

export const cancelOrder = ({ id, data }) => axios.post(`${API_PATH}/purchase-order/${id}/cancel`, data);

export const cancelItems = ({ id, data }) => axios.post(`${API_PATH}/purchase-order/${id}/cancel-items`, data);

export const keheCancelItems = ({ id, data }) => axios.post(`${API_PATH}/purchase-order/${id}/cancel-items-without-repush`, data);

export const keheCancelItemsAndRedecide = ({ id, data }) => axios.post(`${API_PATH}/purchase-order/${id}/cancel-items-and-redecide`, data);
