import React from 'react';
import get from 'lodash/get';

export const resolvePOItemsGridProps = {
    customFields: {
        startPosition: 10,
        hiddenFields: ['product_description'],
    },
    columns: [
        {
            field: 'customValues.product_name',
            label: 'Name',
            type: 'string',
            isSortable: true,
            isFilterable: true,
        },
        {
            field: 'customValues.product_sku',
            label: 'SKU',
            type: 'string',
            isSortable: true,
            isFilterable: true,
        },
        {
            field: 'relatedVendorProduct.sku',
            gqlFields: ['product_id', 'relatedVendorProduct.sku'],
            label: 'Vendor SKU',
            type: 'string',
            isSortable: true,
            isFilterable: true,
        },
        {
            field: 'quantity',
            label: 'Qty',
            type: 'number',
            isSortable: true,
            isFilterable: true,
        },
        {
            field: 'trackingCodes.tracking_code',
            label: 'Tracking Number',
            type: 'string',
            isSortable: true,
            isFilterable: true,
            template: ({ originalValue: { row: { trackingCodes = [] } } = {} }) => (
                <div>
                    {trackingCodes.map((trackingCode, index) => (
                        <div key={index}>{get(trackingCode, 'tracking_code', '-')}</div>
                    ))}
                </div>
            ),
        },
        {
            field: 'trackingCodes.quantity',
            label: 'Qty Included In Shipment',
            type: 'number',
            isSortable: true,
            isFilterable: true,
            template: ({ originalValue: { row: { trackingCodes = [] } } = {} }) => (
                <div>
                    {trackingCodes.map((trackingCode, index) => (
                        <div key={index}>{get(trackingCode, 'quantity', '-')}</div>
                    ))}
                </div>
            ),
        },
        {
            field: 'price',
            label: 'Unit',
            type: 'number',
            isSortable: true,
            isFilterable: true,
        },
        {
            field: 'total_price',
            label: 'Amount',
            type: 'number',
            isSortable: true,
            isFilterable: true,
        },
    ],
    config: {
        bulkDelete: { isVisible: false },
        withPagination: true,
        isSelectable: true,
    },
};
