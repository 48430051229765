import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { Button, FormButtonsWrapper, ImportModal, SmstIcon } from '@skunexus/component-catalog';

import usePrioritizedVendorsAction from './usePrioritizedVendorsAction';

import styles from './styles.module.css';

function PrioritizedVendorsAction(props) {
    const [
        { isCreateMode, fileName, lastImport, exportIsLoading, importIsLoading, importModalProps },
        { handleExport, handleOpenModal },
    ] = usePrioritizedVendorsAction(props);

    if (isCreateMode) {
        return (
            <Alert color="warning">
                Configuration for that section would be available after you create order decision rule.
            </Alert>
        );
    }
    return (
        <div>
            {fileName && lastImport && (
                <div className={styles.fileData}>
                    <div className={styles.fileName}>
                        <SmstIcon icon="FileEarmarkRuled" />
                        {fileName}
                    </div>
                    <div className={styles.importDate}>Last import: {lastImport}</div>
                </div>
            )}
            <FormButtonsWrapper>
                <Button
                    icon="Upload"
                    onClick={handleOpenModal}
                    outline
                    isLoading={importIsLoading}
                    disabled={exportIsLoading}
                >
                    Import
                </Button>
                <Button
                    icon="Download"
                    outline
                    onClick={handleExport}
                    isLoading={exportIsLoading}
                    disabled={importIsLoading || !fileName}
                >
                    Export
                </Button>
            </FormButtonsWrapper>
            <ImportModal {...importModalProps} />
        </div>
    );
}

PrioritizedVendorsAction.propTypes = {};

PrioritizedVendorsAction.defaultProps = {};

export default PrioritizedVendorsAction;
