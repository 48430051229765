/* eslint-disable no-shadow */
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { get } from 'lodash';

import { ajaxExceptionMessage } from '@skunexus/utils';
import * as API from '../../api';

const useKittedProductButton = ({ errorNotification = () => {} } = {}) => {
  const history = useHistory();
  const { id: productId } = useParams();
  const [requestData, setRequestData] = useState({
    isLoading: false,
    isKitted: false,
  });

  function handleClick() {
    history.push(`/products/kitting/${productId}`);
  }

  const fetchRelatedProducts = async ({ productId }) => {
    setRequestData((state) => ({
      ...state,
      isLoading: true,
    }));

    try {
      const result = await API.fetchRelatedProducts({ id: productId });
      const relatedProducts = get(
        result,
        'data.data.product.details.relatedProduct',
        []
      );

      setRequestData((state) => ({
        ...state,
        isLoading: false,
        isKitted: !!relatedProducts.length,
      }));
    } catch (e) {
      errorNotification(ajaxExceptionMessage(e));

      setRequestData((state) => ({
        ...state,
        isLoading: false,
      }));
    }
  };

  useEffect(() => {
    fetchRelatedProducts({ productId });
  }, [productId]);

  return [
    {
      isKittedProduct: requestData.isKitted,
      isLoading: requestData.isLoading,
    },
    { handleClick },
  ];
};

export default useKittedProductButton;
