import set from 'lodash/set';
import reduce from 'lodash/reduce';

import PrioritizedVendorsAction from './packages/PrioritizedVendors/components/PrioritizedVendorsAction';
import * as prioritizedVendorsActionUtils from './packages/PrioritizedVendors/components/PrioritizedVendorsAction/utils';

export const ORDERS_SETTINGS_ROUTE_PREFIX = 'settings/orders';
export const ORDERS_RULES_ROUTE = `/${ORDERS_SETTINGS_ROUTE_PREFIX}/rules`;
export const ORDERS_RULES_FORM_PATH = `/${ORDERS_SETTINGS_ROUTE_PREFIX}/rules/:action(add|edit)/:id?`;
export const ORDERS_RULES_CREATE_FORM_ROUTE = `/${ORDERS_SETTINGS_ROUTE_PREFIX}/rules/add`;
export const ORDERS_RULES_EDIT_FORM_ROUTE = `/${ORDERS_SETTINGS_ROUTE_PREFIX}/rules/edit`;

export default {
  name: 'customOrderDecisionRules',
  before: [],
  after: [],
  setup: (app) => {
    const prioritizedVendorsActionName = 'prioritized_vendors';

    const routeName = 'orderDecisionRuleSettings';
    const route = app.routes.getItem(routeName);
    if (route) {
      const routeProps = route.props;
      route.props = (state) => ({
        ...routeProps(state),
        addFormPageRoute: ORDERS_RULES_CREATE_FORM_ROUTE,
        editFormPageRoute: ORDERS_RULES_EDIT_FORM_ROUTE,
      });
      app.routes.changeItem(routeName, route);
    }

    const customActionComponents = {
      [prioritizedVendorsActionName]: PrioritizedVendorsAction,
    };

    const customActionUtils = {
      [prioritizedVendorsActionName]: prioritizedVendorsActionUtils,
    };

    const orderDecisionRuleForm = app.routes.getItem('orderDecisionRuleForm');
    if (orderDecisionRuleForm) {
      const orderDecisionRuleFormProps = orderDecisionRuleForm.props;

      orderDecisionRuleForm.props = (state) => ({
        ...orderDecisionRuleFormProps(state),
        customActionComponents,
        customActionUtils,
      });

      app.routes.changeItem('orderDecisionRuleForm', orderDecisionRuleForm);
    }
  },
};
