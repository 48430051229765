import { get } from 'lodash';
import DateTimeZoneStore from '@skunexus/datetime-timezone-module';
import useExport from './hooks/useExport';
import useImport from './hooks/useImport';
import useImportModal from './hooks/useImportModal';

const usePrioritizedVendorsAction = (props) => {
    const { details, formData, errorNotification, successNotification, fetchDetails } = props;

    const [{ isLoading: exportIsLoading }, { fetchExport }] = useExport({ errorNotification });
    const [{ isLoading: importIsLoading }, { fetchImport }] = useImport({
        errorNotification,
        successNotification,
        fetchDetails,
    });
    const [{}, { showModal, getImportModalProps }] = useImportModal();

    const getFileName = () => get(formData, 'action.payload.filename');

    const getLastImportDate = () => {
        const date = get(formData, 'action.payload.last_imported');

        if (date) {
            return DateTimeZoneStore.getDateTimeinFeTimeZone({
                date,
            });
        }

        return null;
    };

    const handleExport = async () => {
        await fetchExport({ orderDecisionRuleId: get(details, 'id') });
    };

    const handleImport = async (file) => {
        await fetchImport({ orderDecisionRuleId: get(details, 'id'), file });
    };

    return [
        {
            isCreateMode: !details,
            fileName: getFileName(),
            lastImport: getLastImportDate(),
            exportIsLoading,
            importIsLoading,
            importModalProps: getImportModalProps({ isLoading: importIsLoading, onConfirm: handleImport }),
        },
        { handleExport, handleImport, handleOpenModal: showModal },
    ];
};

export default usePrioritizedVendorsAction;
