import get from 'lodash/get';
import find from 'lodash/find';
import { isNull } from 'lodash';

export default {
    name: 'customPurchaseOrderGrid',
    before: [],
    after: [],
    setup: (app) => {
        const customGridData = (gridProps) => {
            const options = [
                { value: '', label: 'All' },
                { value: '1', label: 'Yes' },
                { value: '0', label: 'No' },
            ];

            gridProps.columns.push({
                field: 'customValues.price_mismatch',
                id: 'customValues.price_mismatch',
                isFilterable: true,
                isSortable: true,
                label: 'Price Mismatch',
                type: 'select',
                options,
                template: ({ originalValue: { value } }) => {
                    if (value === '' || isNull(value)) return '';

                    return get(find(options, ['value', value.toString()]), 'label', '');
                },
            });
            return gridProps;
        };

        const routeName = 'purchaseOrders';
        const route = app.routes.getItem(routeName);
        if (route) {
            const routeProps = route.props;

            route.props = (state) => ({
                ...routeProps(state),
                customGridData,
            });
            app.routes.changeItem(routeName, route);
        }
    },
};
