export default {
    name: 'customPurchaseOrderForm',
    before: [],
    after: [],
    setup: (app) => {
        const customFormSteps = (steps) => steps.map(({ code, enabled, ...rest }) => ({
                code,
                enabled: code === 'purchaseOrderNumber' ? true : enabled,
                ...rest,
            }));

        const purchaseOrderForm = app.routes.getItem('purchaseOrderForm');
        if (purchaseOrderForm) {
            const purchaseOrderFormProps = purchaseOrderForm.props;

            purchaseOrderForm.props = (state) => ({
                ...purchaseOrderFormProps(state),
                customFormSteps,
            });

            app.routes.changeItem('purchaseOrderForm', purchaseOrderForm);
        }
    },
};
