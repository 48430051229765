import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, find, findIndex, reduce } from 'lodash';
import { Counter } from '@skunexus/component-catalog';
import ResolvePo from '../ResolvePurchaseOrder/components/ResolvePo';

/* eslint-disable no-shadow */
function UnfiResolvePurchaseOrder({ cancelItemsAndRedecide, cancelItems }) {
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedItemsQty, setSelectedItemsQty] = useState({});

    const handleSuccess = (setModalData) => {
        setModalData((state) => ({
            ...state,
            isLoading: false,
            isOpen: false,
        }));

        window.location.reload();
    };

    const handleFailure = (setModalData) => {
        setModalData((state) => ({
            ...state,
            isLoading: false,
        }));
    };

    const getPayload = ({ selectedItems }) => ({
        items: selectedItems.map((itemId) => ({
            item_id: itemId,
            qty: selectedItemsQty[itemId],
        })),
    });

    const handleSubmit = ({ details, selectedItems, setModalData, endpoint }) => {
        if (selectedItems.length === 0) {
            setModalData((state) => ({
                ...state,
                isError: true,
            }));
        } else {
            setModalData((state) => ({
                ...state,
                isLoading: true,
                isError: false,
            }));
            endpoint({
                id: details.id,
                data: getPayload({ selectedItems }),
                onSuccess: () => handleSuccess(setModalData),
                onFailure: () => handleFailure(setModalData),
            });
        }
    };

    const handleCancelAndRedecide = ({ details, selectedItems, setModalData }) => {
        handleSubmit({ details, selectedItems, setModalData, endpoint: cancelItemsAndRedecide });
    };

    const handleCancelItems = ({ details, selectedItems, setModalData }) => {
        handleSubmit({ details, selectedItems, setModalData, endpoint: cancelItems });
    };

    const handleSelectionChange = (selected) => {
        if (JSON.stringify(selected) !== JSON.stringify(selectedItems)) {
            setSelectedItems(selected);
        }
    };

    const handleQtyChange = ({ itemId, qty }) => {
        setSelectedItemsQty((state) => ({
            ...state,
            [itemId]: qty,
        }));
    };

    const customGridConfig = (data) => ({
        ...data,
        config: {
            ...data.config,
            pagination: {
                currentPage: 1,
                pageSize: 999,
            },
        },
    });

    const getFailedVendorSkus = (poDetails) => {
        const failedVendorSkusCustomFields = find(get(poDetails, 'customValues', []), [
            'custom_field_id',
            'vendor_failed_skus',
        ]);
        if (failedVendorSkusCustomFields) {
            return get(failedVendorSkusCustomFields, 'value', '').split(',');
        }

        return [];
    };

    const getTrackingCodeSumQty = (trackingCodes) =>
        reduce(trackingCodes, (result, { quantity }) => result + quantity, 0);

    const getFilteredValues = (gridProps, props) => {
        const poDetails = get(props, 'details', {});
        const failedVendorSkus = getFailedVendorSkus(poDetails);

        return gridProps.values.filter(({ relatedVendorProduct, quantity, trackingCodes }) => {
            const trackingCodeSumQty = getTrackingCodeSumQty(trackingCodes);
            return failedVendorSkus.includes(get(relatedVendorProduct, 'sku')) || trackingCodeSumQty !== quantity;
        });
    };

    const customGridData = (gridProps, props) => {
        const filteredValues = getFilteredValues(gridProps, props);

        const { columns = [] } = gridProps;
        const qtyColumnIndex = findIndex(columns, ['field', 'quantity']);
        const showCounterParts = ({ selectedItems, id, value }) => selectedItems.includes(id) && value !== 1;

        gridProps.columns[qtyColumnIndex].template = (templateProps) => {
            const { originalValue: { row, value } = {}, config = {} } = templateProps;
            const { selectedItems = [], selectedItemsQty = {} } = config;
            const { id } = row;

            const showCounterPartsFlag = showCounterParts({ selectedItems, id, value });
            const isDisabled = !selectedItems.includes(id);

            return (
                <Counter
                    minimumValue={1}
                    total={value}
                    value={isDisabled ? value : get(selectedItemsQty, id, value)}
                    showCounterButtons={showCounterPartsFlag}
                    showInputCounter={showCounterPartsFlag}
                    showTotal={showCounterPartsFlag}
                    isDisabled={isDisabled}
                    onChange={(value) => handleQtyChange({ itemId: id, qty: value })}
                />
            );
        };

        return {
            ...gridProps,
            config: {
                ...gridProps.config,
                withFooter: false,
                selectedItems,
                selectedItemsQty,
            },
            itemsCount: filteredValues.length,
            values: filteredValues,
        };
    };

    return (
        <ResolvePo
            resetButtonLabel={(selectedItemsCount) => `Cancel Items and Redecide (${selectedItemsCount})`}
            confirmButtonLabel={(selectedItemsCount) => `Cancel Items (${selectedItemsCount})`}
            customGridConfig={customGridConfig}
            customGridData={customGridData}
            onSelectionChange={handleSelectionChange}
            onResetButtonClick={handleCancelAndRedecide}
            onConfirmButtonClick={handleCancelItems}
        />
    );
}

UnfiResolvePurchaseOrder.propTypes = {
    cancelItemsAndRedecide: PropTypes.func.isRequired,
    cancelItems: PropTypes.func.isRequired,
};

UnfiResolvePurchaseOrder.defaultProps = {};

export default UnfiResolvePurchaseOrder;
/* eslint-enable */
