import React from 'react';
import { Button, FormButtonsWrapper } from '@skunexus/component-catalog';

import useKittedProductButton from './useKittedProductButton';

import styles from './styles.module.css';

function KittedProductButton(props) {
    const [{ isKittedProduct, isLoading }, { handleClick }] = useKittedProductButton(props);

    if (!isKittedProduct || isLoading) {
        return null;
    }
    return (
        <FormButtonsWrapper className={styles.buttonsWrapper}>
            <Button outline onClick={handleClick}>
                Kitted Product
            </Button>
        </FormButtonsWrapper>
    );
}

KittedProductButton.propTypes = {};

KittedProductButton.defaultProps = {};

export default KittedProductButton;
