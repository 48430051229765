import { constants, gql } from '@skunexus/utils';

const {
    CONFIG: { API_PATH },
} = constants;

export function fetchRelatedProducts({ id }) {
    return gql.getGraphQLDetails({
        url: `${API_PATH}/query`,
        config: { domain: 'product' },
        fields: `
            relatedProduct {
                product_id
                related_product_id
            }
        `,
        payload: { id },
    });
}
