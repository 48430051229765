import React from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { subspaced, parentSpaced, ParentSpaceProvider } from 'react-redux-subspace';
import { ConnectedRouter } from 'connected-react-router';

import Auth from '@skunexus/auth';
import Notifications from '@skunexus/notification';
import store, { history } from './store';
import PortalSwitch from './PortalSwitch';
import SmartLookInit from './thirdPartLibraries/smartLook/SmartLookInit';
import SmartLookForLogged from './thirdPartLibraries/smartLook/SmartLookForLogged';
import LocalizejsInit from './thirdPartLibraries/localizejs/LocalizejsInit';
import LocalizejsInitializeWidget from './thirdPartLibraries/localizejs/LocalizejsInitializeWidget';

import app from './config';

const NotificationsModule = subspaced(app.selectors.getItem('getNotifications'), { context: ReactReduxContext })(
    Notifications,
);
const AuthModule = subspaced(app.selectors.getItem('getAuth'), { context: ReactReduxContext })(Auth);
const PortalSwitchModule = parentSpaced({ context: ReactReduxContext })(PortalSwitch);

function App() {
    return (
        <Provider store={store}>
            <SmartLookInit />
            <LocalizejsInit />
            <ConnectedRouter history={history} context={ReactReduxContext}>
                <NotificationsModule />
                <AuthModule {...app.general.getAll()} history={history}>
                    <SmartLookForLogged />
                    <LocalizejsInitializeWidget />
                    <ParentSpaceProvider context={ReactReduxContext}>
                        <PortalSwitchModule />
                    </ParentSpaceProvider>
                </AuthModule>
            </ConnectedRouter>
        </Provider>
    );
}

export default App;
