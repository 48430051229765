import { values } from 'lodash';
import { ORDERS_SETTINGS_ROUTE_PREFIX } from '../../modules/orders/consts';
import { VENDOR_TITLE_PREFIX, VENDOR_ROUTE_PREFIX } from '../../modules/vendors/consts';
import withVendorTabsWrapper from '../../modules/vendors/components/withVendorTabsWrapper';
import withSettingsTabsWrapper from '../../modules/settings/components/withSettingsTabsWrapper';

const createVendorSchedulePage = (app) => {
    app.vendorTabs.addItem('autoDecisionSchedule', {
        id: 'autoDecisionSchedule',
        label: 'Auto-Decision Schedule',
        title: `${VENDOR_TITLE_PREFIX} Auto-Decision Schedule`,
        link: `/${VENDOR_ROUTE_PREFIX}/schedule/:vendorId`,
        permission: 'vendors.show',
        order: 90,
    });

    app.routes.addItem('vendorAutoDecisionSchedule', {
        name: 'VendorAutoDecisionSchedule',
        path: app.vendorTabs.getItem('autoDecisionSchedule').link,
        title: app.vendorTabs.getItem('autoDecisionSchedule').title,
        permission: app.vendorTabs.getItem('autoDecisionSchedule').permission,
        breadcrumbConfig: {
            title: `${app.vendorTabs.getItem('autoDecisionSchedule').label}`,
            isReplaceble: true,
            isTab: true,
        },
        props: () => ({}),
        loader: () => import('./packages/VendorAutoDecisionSchedule/core'),
        render: withVendorTabsWrapper(app),
    });
};

const createVendorScheduleSettingsPage = (app) => {
    const link = `/${ORDERS_SETTINGS_ROUTE_PREFIX}/vendor-schedule`;
    const title = `Vendors Auto-Decision Schedule`;
    const permission = [];

    const settingsOrdersTab = app.settingsTabs.getItem('orders');
    const orderTabs = values(app.settingsOrderTabs.getAll());

    app.settingsTabs.changeItem('orders', {
        ...settingsOrdersTab,
        children: [
            ...orderTabs,
            {
                id: 'vendorAutoDecisionScheduleSettings',
                label: title,
                title,
                link,
                permission: [],
                order: 90,
            },
        ],
    });

    app.routes.addItem('settingsVendorAutoDecisionSchedule', {
        name: 'SettingsVendorAutoDecisionSchedule',
        path: link,
        title,
        permission,
        breadcrumbConfig: {
            title,
            isTab: true,
            clearPath: true,
        },
        props: () => ({}),
        loader: () => import('./packages/SettingsVendorAutoDecisionSchedule/core'),
        render: withSettingsTabsWrapper(app),
    });
};

export default {
    name: 'vendorAutoSchedule',
    before: [],
    after: [],
    setup: (app) => {
        createVendorSchedulePage(app);
        createVendorScheduleSettingsPage(app);
    },
};
