import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectors } from '@skunexus/purchase-order-overview';
import { resolve } from '../../core/actions';
import Components from './UnfiResolvePurchaseOrder';

const { getItemsRows } = selectors;

const mapStateToProps = createStructuredSelector({
    items: getItemsRows,
});

const mapDispatchToProps = {
    cancelItemsAndRedecide: resolve.keheCancelItemsAndRedecide.TRIGGER,
    cancelItems: resolve.keheCancelItems.TRIGGER,
};

export default connect(mapStateToProps, mapDispatchToProps)(Components);
