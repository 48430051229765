const PURCHASE_ORDER_STATUSES = {
    ACTIVE: 'active',
    PENDING: 'pending',
    DRAFT: 'draft',
    CANCELLED: 'cancelled',
    CLOSED: 'closed',
    ERROR: 'error',
};

const PURCHASE_ORDER_REPUSH_OPTIONS = ['30 minutes', '3 hours', '6 hours', '9 hours'];

export { PURCHASE_ORDER_STATUSES, PURCHASE_ORDER_REPUSH_OPTIONS };
