import { connect } from 'react-redux';
import { actions as notification } from '@skunexus/notification';

import { actions as orderDecisionFormActions } from '@skunexus/order-decision-rules-form';
import Components from './PrioritizedVendorsAction';

const mapStateToProps = (state) => ({
    details: state.details.details,
});

const mapDispatchToProps = {
    fetchDetails: orderDecisionFormActions.details.fetchDetails.TRIGGER,
    successNotification: notification.success,
    errorNotification: notification.error,
};

export default connect(mapStateToProps, mapDispatchToProps)(Components);
