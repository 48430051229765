import { TITLE_PREFIX, INVENTORY_INDEX_ROUTE } from './consts';

export default () => ({
    inventory: {
        label: TITLE_PREFIX,
        title: `${TITLE_PREFIX} Settings`,
        link: INVENTORY_INDEX_ROUTE,
        permission: 'settings - low stock notifications.save',
        order: 10,
    },
});
