import { TITLE_PREFIX, PO_SETTINGS_STATUSES_ROUTE, PO_SETTINGS_NUMBER_ROUTE } from './consts';

export default () => ({
    statuses: {
        id: 'Statuses',
        label: 'Statuses',
        title: `${TITLE_PREFIX} Statuses`,
        link: PO_SETTINGS_STATUSES_ROUTE,
        permission: 'purchase orders - statuses.index',
        order: 10,
    },
    poNumber: {
        id: 'PO Number',
        label: 'PO Number',
        title: `${TITLE_PREFIX}  Order Number`,
        link: PO_SETTINGS_NUMBER_ROUTE,
        permission: 'settings - general.save',
        order: 20,
    },
});
