import { useState } from 'react';

const EXAMPLE_CSV_CONTENT = [
    [
        'dest_state',
        'zipcode_starts_with',
        'autoship_1',
        'autoship_2',
        'autoship_3',
        'autoship_4',
        'autoship_5',
        'autoship_6',
        'autoship_7',
        'autoship_8',
        'autoship_9',
        'autoship_10',
        'autoship_11',
        'autoship_12',
        'non_autoship_1',
        'non_autoship_2',
        'non_autoship_3',
        'non_autoship_4',
        'non_autoship_5',
        'non_autoship_6',
        'non_autoship_7',
        'non_autoship_8',
        'non_autoship_9',
        'non_autoship_10',
        'non_autoship_11',
        'non_autoship_12',
    ],
];

const useImportModal = () => {
    const [isOpen, setModalOpen] = useState(false);

    const showModal = () => setModalOpen((state) => !state);

    const getImportModalProps = ({ isLoading, onConfirm }) => ({
        isSubmitting: isLoading,
        onConfirm: (acceptedFiles) => {
            setModalOpen((state) => !state);
            onConfirm(acceptedFiles[0]);
        },
        onClose: () => setModalOpen((state) => !state),
        modalProps: {
            isOpen,
            headerLabel: 'Import Vendors',
        },
        exampleFile: EXAMPLE_CSV_CONTENT,
    });

    return [{}, { showModal, getImportModalProps }];
};

export default useImportModal;
