import { all, put, takeEvery } from 'redux-saga/effects';

import { actions as notification } from '@skunexus/notification';

import { resend, resolve } from './actions';
import resendSagas from './resend/sagas';
import resolveSagas from './resolve/sagas';

function* notifyAboutFailure(action) {
    yield put(notification.error(action.payload));
}

function* notifyAboutSuccess(action) {
    yield put(notification.success(action.payload));
}

function* notifyAboutRepushSuccess() {
    yield put(notification.success({ message: 'Order is successfully repushed.' }));
}

function* moduleSagas() {
    yield all([
        takeEvery(resend.repushOrder.SUCCESS, notifyAboutRepushSuccess),
        takeEvery(resend.repushOrder.FAILURE, notifyAboutFailure),

        takeEvery(resolve.cancelPO.SUCCESS, notifyAboutSuccess),
        takeEvery(resolve.cancelPO.FAILURE, notifyAboutFailure),
        takeEvery(resolve.cancelItems.SUCCESS, notifyAboutSuccess),
        takeEvery(resolve.cancelItems.FAILURE, notifyAboutFailure),
        takeEvery(resolve.keheCancelItems.SUCCESS, notifyAboutSuccess),
        takeEvery(resolve.keheCancelItems.FAILURE, notifyAboutFailure),
        takeEvery(resolve.keheCancelItemsAndRedecide.SUCCESS, notifyAboutSuccess),
        takeEvery(resolve.keheCancelItemsAndRedecide.FAILURE, notifyAboutFailure),
        resendSagas(),
        resolveSagas(),
    ]);
}

export default moduleSagas;
