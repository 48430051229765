import axios from 'axios';
import { constants, createBlobFromResponse } from '@skunexus/utils';

const {
    CONFIG: { API_PATH },
} = constants;

export const importPrioritizedVendors = ({ id, file }) => {
    const formData = new FormData();
    
    formData.append('csv', file);
    const endpoint = `${API_PATH}/prioritized-vendors-action/import/${id}`;

    return axios.post(endpoint, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const exportPrioritizedVendors = ({ id }) =>
    axios
        .get(`${API_PATH}/prioritized-vendors-action/export/${id}`, {
            headers: {
                accept: 'text/csv',
            },
        })
        .then(createBlobFromResponse({ fileName: 'prioritized-vendors.csv' }));
