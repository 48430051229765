import withSettingsTabsWrapper from '../settings/components/withSettingsTabsWrapper';

export default (app) => ({
    inventorySettings: {
        path: app.settingsTabs.getItem('inventory').link,
        title: app.settingsTabs.getItem('inventory').title,
        permission: app.settingsTabs.getItem('inventory').permission,
        breadcrumbConfig: {
            name: `${app.settingsTabs.getItem('inventory').label}`,
            clearPath: true,
        },
        props: () => ({
            indexPageRoute: '/settings/inventory-settings',
        }),
        loader: () => import('@skunexus/inventory-settings'),
        render: withSettingsTabsWrapper(app),
    },
});
