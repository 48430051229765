import { call, put, all, takeEvery } from 'redux-saga/effects';
import { ajaxExceptionMessage } from '@skunexus/utils';

import * as API from '../../api';
import { repushOrder } from './actions';

function* repushOrderSaga(action) {
    try {
        const { id } = action.payload;
        const response = yield call(API.rePushOrder, { id });

        const { data } = response;
        if (!data) {
            throw new Error("Couldn't repush the order");
        }
        yield put(repushOrder.SUCCESS({ ...action.payload, customValues: data }));
    } catch (e) {
        yield put(repushOrder.FAILURE(ajaxExceptionMessage(e)));
    } finally {
        yield put(repushOrder.FULFILL());
    }
}

function* resendSagas() {
    yield all([takeEvery(repushOrder.TRIGGER, repushOrderSaga)]);
}

export default resendSagas;
