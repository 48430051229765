export default {
    name: 'customRma',
    before: [],
    after: [],
    setup: (app) => {
        const rmaGridRoute = app.routes.getItem('rmaGrid');
        if (rmaGridRoute) {
            rmaGridRoute.loader = () => import('./packages/RmaWrapper/core');
            app.routes.changeItem('rmaGrid', rmaGridRoute);
        }

        app.routes.addItem('rmaCreate', {
            path: '/rma/create/:label',
            title: 'Create RMA',
            permission: ['rma.index'],
            props: () => ({
                gridPageRoute: '/rma',
            }),
            loader: () => import('./packages/CreateRma/core'),
        });
    },
};
