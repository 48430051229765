import values from 'lodash/values';
import createConfigurableObject from '../../../utils/configurationObject';
import withSettingsTabsWrapper from '../../modules/settings/components/withSettingsTabsWrapper';

export default {
    name: 'forbiddenStatuses',
    before: [],
    after: [],
    setup: (app) => {
        app.settingsGlobalTabs = app.settingsGlobalTabs || createConfigurableObject();

        app.settingsGlobalTabs.addItem('forbidden-statuses', {
            id: 'forbidden-statuses',
            label: 'Forbidden Statuses',
            title: 'Forbidden Statuses',
            link: '/settings/global/forbidden-statuses',
            permission: 'settings - general.save',
        });

        const settingsGlobalTab = app.settingsTabs.getItem('global');

        app.settingsTabs.changeItem('global', {
            ...settingsGlobalTab,
            children: values(app.settingsGlobalTabs.getAll()),
        });

        app.routes.addItem('forbiddenStatusesForm', {
            path: app.settingsGlobalTabs.getItem('forbidden-statuses').link,
            title: app.settingsGlobalTabs.getItem('forbidden-statuses').title,
            permission: app.settingsGlobalTabs.getItem('forbidden-statuses').permission,
            props: () => ({}),
            loader: () => import('./core'),
            render: withSettingsTabsWrapper(app),
        });
    },
};
