import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import startCase from 'lodash/startCase';
import { Container, Row, Col } from 'reactstrap';

import { Button } from '@skunexus/component-catalog';

import ResolvePurchaseOrder from '../ResolvePurchaseOrder';
import KeheResolvePurchaseOrder from '../KeheResolvePurchaseOrder';
import UnfiResolvePurchaseOrder from '../UnfiResolvePurchaseOrder';

import { PURCHASE_ORDER_STATUSES, PURCHASE_ORDER_REPUSH_OPTIONS } from '../../core/constants';

import styles from './styles.module.css';

const KEHE_VENDORS = ['KeHE DC 16', 'KeHE DC 18', 'KeHE DC 41', 'Drive Medical'];
const UNFI_VENDORS = ['UNFI - GLY', 'UNFI - GRW', 'UNFI - PHI'];

function Module({ details, statuses, repushOrder }) {
    const orderStatus = find(statuses, ['id', get(details, 'status_id')]);

    const handleRepushOrder = () => {
        repushOrder({ id: details.id });
    };

    const getResolvePOComponent = (_details) => {
        const vendorName = get(_details, 'vendor.name');

        if (KEHE_VENDORS.includes(vendorName)) return KeheResolvePurchaseOrder;
        if (UNFI_VENDORS.includes(vendorName)) return UnfiResolvePurchaseOrder;

        return ResolvePurchaseOrder;
    };

    if (orderStatus && get(orderStatus, 'label') === startCase(PURCHASE_ORDER_STATUSES.ERROR)) {
        const ResolvePoComponent = getResolvePOComponent(details);
        return (
            <Col xs="12" sm="auto">
                <Container className={`p-4 ${styles.resendWrapper}`}>
                    <Row>
                        <Col className="font-weight-bold">Retry Intervals:</Col>
                    </Row>
                    {PURCHASE_ORDER_REPUSH_OPTIONS.map((option, index) => (
                        <Row key={index}>
                            <Col xs="auto" className="font-italic">
                                {`Step ${index + 1}`}:
                            </Col>
                            <Col>{option}</Col>
                        </Row>
                    ))}
                    <Row className="mt-2">
                        <Col>
                            <Button size="md" onClick={handleRepushOrder}>
                                Resend Purchase Order
                            </Button>
                        </Col>
                    </Row>
                    <ResolvePoComponent />
                </Container>
            </Col>
        );
    }

    return null;
}

Module.propTypes = {
    details: PropTypes.object.isRequired,
    statuses: PropTypes.array.isRequired,
    repushOrder: PropTypes.func.isRequired,
};

export default Module;
